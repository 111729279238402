import * as React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Avatar, Typography, Divider } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import LogoutIcon from '@mui/icons-material/Logout';
import DescriptionIcon from '@mui/icons-material/Description';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';

function AppDrawer() {
    const [isOpen, setIsOpen] = React.useState(false);
    const navigate = useNavigate();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsOpen(open);
    };

    const handleOnClick = (path) => () => {
        navigate(path);
    };

    const list = () => (
        <div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
                <ListItem>
                    <Avatar>SA</Avatar>
                    <Typography>Saulo de Siqueira</Typography>
                </ListItem>
                <Divider></Divider>
                <ListItem button onClick={handleOnClick('/profile')}>
                    <ListItemIcon>
                        <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Perfil" />
                </ListItem>
                <ListItem button onClick={handleOnClick('/settings')}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Configurações" />
                </ListItem>
                <ListItem button onClick={handleOnClick('/Relatórios')}>
                    <ListItemIcon>
                        <AssessmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Relatórios" />
                </ListItem>
                <ListItem button onClick={handleOnClick('/irpf')}>
                    <ListItemIcon>
                        <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="IRPF" />
                </ListItem>
                <Divider></Divider>
                <ListItem button onClick={handleOnClick('/irpf')}>
                    <ListItemIcon>
                        <LogoutIcon></LogoutIcon>
                    </ListItemIcon>
                    <LogoutButton></LogoutButton>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div>
            <IconButton onClick={toggleDrawer(true)}>
                <MenuIcon sx={{ color: 'white' }} />
            </IconButton>
            <Drawer
                anchor="left"
                open={isOpen}
                onClose={toggleDrawer(false)}
                sx={{
                    width: 240, // Define a largura do drawer
                    flexShrink: 0,
                    top: 0,
                    '& .MuiDrawer-paper': {
                        width: 240, // Garante que o papel do drawer também tenha a largura definida
                    },
                }}
            >
                {list()}
            </Drawer>
        </div>
    );
}

export default AppDrawer;
