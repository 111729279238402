import React, { useEffect, useState } from 'react';

const IncrementalCounter = ({ start = 0, end, duration = 3000 }) => {
    // Aumente a duração para tornar a animação mais lenta
    const [currentValue, setCurrentValue] = useState(start);

    // Função easeOutQuint para uma desaceleração mais suave e prolongada
    const easeOutQuint = (t, b, c, d) => {
        return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
    };

    useEffect(() => {
        let startTimestamp;

        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min(timestamp - startTimestamp, duration); // Certifique-se de que o progresso não ultrapasse a duração
            const easedValue = easeOutQuint(progress, start, end - start, duration);
            setCurrentValue(easedValue > end ? end : easedValue);
            if (progress < duration) {
                window.requestAnimationFrame(step);
            } else {
                setCurrentValue(end); // Assegura o valor final exato após a animação
            }
        };

        window.requestAnimationFrame(step);

        // Limpa o valor atual quando o componente desmontar
        return () => setCurrentValue(start);
    }, [start, end, duration]);

    return <span>${currentValue.toFixed(2)}</span>;
};

export default IncrementalCounter;
