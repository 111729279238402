import React from 'react';
import { Box, Divider } from '@mui/material';
import { useAuth } from '../contexts/AuthContext'; // Verifique o caminho
import Menu from './Menu';

function FooterMenu() {
    const { user } = useAuth();

    return (
        <Box
            sx={{
                width: '100%',
                position: 'fixed',
                bottom: 0,
                left: 0,
                zIndex: 1300,
                // Ajustando estilos condicionais com breakpoints
                pb: { xs: 1, sm: 1 }, // Ajuste o paddingBottom se necessário
                minHeight: { xs: '64px', sm: '56px' }, // Usando minHeight para garantir cobertura completa
                backgroundColor: 'background.paper', // Defina uma cor de fundo para evitar transparências indesejadas
            }}
        >
            <Divider />
            {user && <Menu />}
        </Box>
    );
}

export default FooterMenu;
