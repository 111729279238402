import '../components/ProfitCard.css';

import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from 'axios';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import IncrementalCounter from './IncrementalCounter';

function ProfitCard() {
    const [profit, setProfit] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchProfit = async () => {
            const token = localStorage.getItem('access_token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/pnl`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setProfit(parseFloat(response.data.total_pnl)); // Converte e armazena como número
            } catch (error) {
                console.error('Erro:', error);
                setError('Falha ao carregar o lucro.');
            } finally {
                setLoading(false);
            }
        };
        fetchProfit();
    }, []);

    return (
        <Card sx={{ minWidth: 275, marginBottom: 2, backgroundColor: '#424242', color: '#ffffff' }} elevation={3} className="">
            <CardContent>
                <Typography sx={{ fontSize: 14, color: 'white' }} gutterBottom>
                    Lucro acumulado
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <Typography variant="body2" style={{ color: '#ff1744' }}>
                            {error}
                        </Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h5" component="div" sx={{ mr: 1 }}>
                                <IncrementalCounter start={0} end={profit} duration={4000} />
                            </Typography>
                            <TrendingUpIcon sx={{ color: 'green' }} />
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}

export default ProfitCard;
