import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            // Enviar solicitação POST para a rota de logout
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Incluir o token de autenticação no cabeçalho, se necessário
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });

            if (response.ok) {
                // Tratar sucesso do logout
                console.log('Logout successful');
                // Limpar token armazenado localmente ou realizar outras ações de limpeza
                localStorage.removeItem('access_token');
                navigate('/login');
            } else {
                // Tratar falhas na resposta
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    return <Button onClick={handleLogout}>Logout</Button>;
};

export default LogoutButton;
