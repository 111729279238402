import React, { useState } from 'react';
import { TextField, Button, Container, Paper, Typography, Box, Divider, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext'; // Ajuste o caminho do import conforme sua estrutura de pastas

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState('');
    const [severety, setSeverety] = useState('');

    function loginUser(credentials) {
        // Primeiro, obtemos o cookie CSRF
        axios
            .get(`${process.env.REACT_APP_API_URL}/sanctum/csrf-cookie`, {
                withCredentials: true, // Necessário para o suporte a cookies com CORS
            })
            .then(() => {
                // Depois que o cookie CSRF é configurado, fazemos o login
                axios
                    .post(`${process.env.REACT_APP_API_URL}/api/login`, credentials, {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                        },
                        withCredentials: true, // Necessário para o suporte a cookies com CORS
                    })
                    .then((response) => {
                        localStorage.setItem('access_token', response.data.access_token);
                        login(response.data.user);
                        navigate('/');
                    })
                    .catch((error) => {
                        setOpenSnackbar(true);
                        setMessage(error.response.data.message);
                        setSeverety('error');
                        console.error(error.response ? error.response.data : error.message);
                    });
            })
            .catch((error) => {
                console.error(error.response ? error.response.data : error.message);
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await loginUser({
            email: email,
            password: password,
        });
    };

    const handleClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <Container component="main" maxWidth="sm">
            <Paper elevation={6} style={{ padding: '16px', marginTop: '20px' }}>
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
                    <Alert severity={severety}>{message}</Alert>
                </Snackbar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="e-Mail"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                        Entrar
                    </Button>
                    <Divider></Divider>
                    <p>Ainda não é registrado?</p>
                    <Button onClick={() => navigate('/register')} fullWidth sx={{ mt: 3, mb: 2 }}>
                        Registrar
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}

export default Login;
