import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, useTheme, useMediaQuery, SpeedDial } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
// import SpeedDialIcon from '@mui/material/SpeedDialIcon';

function Menu() {
    const [value, setValue] = useState(0);
    let navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                navigate('/');
                break;
            case 1:
                navigate('/crypto/index');
                break;
            case 2:
                navigate('/crypto');
                break;
            case 3:
                navigate('/settings');
                break;
            default:
                break;
        }
    };

    return (
        // <div style={{ display: isMobile ? "block" : "none" }}>
        <div style={{ display: isMobile ? 'block' : 'block', position: 'relative' }}>
            <BottomNavigation value={value} onChange={handleChange} showLabels>
                {/* Ajuste a posição para 'absolute', centralize na parte inferior e horizontalmente */}
                {/* <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        left: '50%', // Define a posição esquerda para 50% do contêiner pai
                        transform: 'translateX(-50%)', // Move o SpeedDial para a esquerda pela metade de sua largura para centralizar
                    }}
                    icon={<SpeedDialIcon />}
                ></SpeedDial> */}
                <BottomNavigationAction label="Dashboard" icon={<HomeIcon />} />
                <BottomNavigationAction label="Meus Ativos" icon={<ListIcon />} />
                <BottomNavigationAction label="Transação" icon={<AddIcon />} />
                <BottomNavigationAction label="Opções" icon={<SettingsIcon />} />
            </BottomNavigation>
        </div>
    );
}

export default Menu;
