import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import PaidIcon from '@mui/icons-material/Paid';
import IncrementalCounter from './IncrementalCounter';

function InvestmentCard() {
    const [investment, setInvestment] = useState(0); // Armazenando como número
    const [loading, setLoading] = useState(true); // Inicie como true para mostrar o carregamento inicialmente
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchProfit = async () => {
            setLoading(true); // Garanta que o estado de loading seja redefinido em cada chamada
            const token = localStorage.getItem('access_token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/investment`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setInvestment(parseFloat(response.data.data)); // Converta para float para armazenar como número
            } catch (error) {
                console.error('Erro:', error);
                setError('Falha ao carregar o lucro.');
            } finally {
                setLoading(false);
            }
        };
        fetchProfit();
    }, []);

    return (
        <Card sx={{ minWidth: 275, marginBottom: 2, backgroundColor: '#027fed', color: '#ffffff' }} elevation={3}>
            <CardContent>
                <Typography sx={{ fontSize: 14, color: 'white' }} gutterBottom>
                    Valor investido
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <Typography variant="body2" style={{ color: '#ff1744' }}>
                            {error}
                        </Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h5" component="div" sx={{ mr: 1 }}>
                                <IncrementalCounter start={0} end={investment} duration={2000} />
                            </Typography>
                            <PaidIcon sx={{ color: 'white' }} />
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}

export default InvestmentCard;
