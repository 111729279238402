import React from 'react';
import BalanceCard from '../../components/BalanceCard'; // Corrigido BalancedCard para BalanceCard
import ProfitCard from '../../components/ProfitCard';
import CryptoLottie from '../../components/CryptoLottie';
import { Container, Grid } from '@mui/material';
import TopCryptos from '../../components/TopCryptos';
import InvestmentCard from '../../components/InvestmentCard';
import GoalGaugeCard from '../../components/GoalGaugeCard'; // Usei GoalGaugeCard aqui, supondo que seja o nome correto
import CryptoSummaryChart from '../../components/CryptoSummaryChart';

function Home() {
    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                {/* Investment, Balance, and Profit Cards */}
                <Grid item xs={12} sm={4}>
                    <InvestmentCard />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <BalanceCard />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <ProfitCard profit={'R$ 1000,00'} />
                </Grid>

                <Grid item xs={12}>
                    <CryptoSummaryChart />
                </Grid>

                {/* Goal Gauge Card */}
                <Grid item xs={12}>
                    <GoalGaugeCard
                        currentAmount={5000} // Valor atual
                        goalAmount={10000} // Valor da meta
                        endDate={'December 2024'} // Data final
                    />
                </Grid>

                {/* Top Cryptos */}
                <Grid item xs={12} sm={6}>
                    <TopCryptos type="win" title="Maior lucro" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TopCryptos type="loose" title="Menor lucro" />
                </Grid>

                {/* Crypto Lottie Animation */}
                <Grid item xs={12}>
                    <CryptoLottie />
                </Grid>
            </Grid>
        </Container>
    );
}

export default Home;
