import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthProvider } from './contexts/AuthContext';

import AppDrawer from './components/AppDrawer';
import AppRoutes from './routes';
import FooterMenu from './components/FooterMenu';

// Estilo global pode ser movido para App.css ou aqui com sx prop se preferir
import './App.css';

// Ajustando o tema para ser dark ou light com base em uma preferência, por exemplo
const theme = createTheme({
    palette: {
        mode: 'light', // Mudança direta aqui caso queira alternar entre 'light' e 'dark'
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <Router>
                    <AppBar position="fixed" sx={{ backgroundColor: '#292929' }}>
                        <Toolbar>
                            <AppDrawer />
                            <Typography variant="h6">Hodlcoin</Typography>
                        </Toolbar>
                    </AppBar>
                    <Container maxWidth="xl" sx={{ my: 4, paddingTop: 5, paddingBottom: 5 }}>
                        <AppRoutes />
                    </Container>
                    <FooterMenu />
                </Router>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
