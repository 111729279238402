import React, { useState } from 'react';
import { TextField, Button, Container, Paper, Typography, Box, Snackbar, Divider, Backdrop, CircularProgress } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [apiMessage, setApiMessage] = useState('');
    const [severity, setSeverity] = useState('info');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true); // Bloqueia a tela

        if (password !== confirmPassword) {
            setSeverity('error');
            setApiMessage('As senhas não coincidem.');
            setSnackbarOpen(true);
            setLoading(false); // Desbloqueia a tela
            return;
        }

        const formData = {
            name: name,
            email: email,
            password: password,
            password_confirmation: confirmPassword,
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/register`, formData)
            .then((response) => {
                setSeverity('success');
                setApiMessage(response.data.message);
                setSnackbarOpen(true);
                navigate('/login'); // Redireciona após o sucesso
            })
            .catch((error) => {
                setSeverity('error');
                setApiMessage(error.response ? 'Ocorreu um erro ao cadastrar o usuário: ' + error.response.data.message : 'Erro ao fazer a requisição: ' + error.message);
                setSnackbarOpen(true);
            })
            .finally(() => {
                setLoading(false); // Desbloqueia a tela independente do resultado
            });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Backdrop open={loading} style={{ zIndex: 1, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
                    {apiMessage}
                </Alert>
            </Snackbar>
            <Paper elevation={6} style={{ padding: '16px', marginTop: '20px' }}>
                <Typography component="h1" variant="h5">
                    Registro de Usuário
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    {/* Campos do formulário */}
                    <TextField variant="outlined" margin="normal" required fullWidth id="name" label="Nome completo" name="name" autoFocus value={name} onChange={(e) => setName(e.target.value)} />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Endereço de E-mail"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password_confirmation"
                        label="Confirmar Senha"
                        type="password"
                        id="password_confirmation"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                        Registrar
                    </Button>
                    <Divider />
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Já é registrado?
                    </Typography>
                    <Button onClick={() => navigate('/login')} fullWidth sx={{ mt: 1 }}>
                        Login
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}

export default Register;
