import React from 'react';
import Lottie from 'react-lottie';
import animationData from './crypto_lottie.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const CryptoLottie = () => {
    return <Lottie options={defaultOptions} height={300} width={300} />;
};

export default CryptoLottie;
