import React from 'react';
import { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, Chip, IconButton, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TrendingDown, Upcoming, UpcomingRounded } from '@mui/icons-material';
import DialogDeleteTransactionDetails from './DialogDeleteTransactionDetails';

const getValueStyle = (value) => ({
    color: value === 'SELL' ? 'red' : 'green',
});

function TransactionDetails({ transactionDetails, onUpdateTransactionDetails }) {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleDelete = (id) => {
        setSelectedId(id); // Define o ID da transação selecionada
        setOpenDialog(true); // Abre o diálogo
    };

    const handleCloseDialog = (confirmDelete) => {
        setOpenDialog(false);

        if (confirmDelete) {
            const token = localStorage.getItem('access_token');
            fetch(`${process.env.REACT_APP_API_URL}/api/portfolio/detail/${selectedId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                method: 'DELETE',
            })
                .then((response) => {
                    console.log(response);
                    // Filtra fora a transação deletada e atualiza o estado no componente pai
                    const updatedRows = transactionDetails.filter((row) => row.id !== selectedId);
                    onUpdateTransactionDetails(updatedRows); // Atualiza o estado no componente pai
                })
                .catch((error) => {
                    console.error('Erro ao deletar detalhes do ativo:', error);
                });
            console.log('Deletar transação com ID:', selectedId);
            // Atualiza o estado para remover a transação deletada
            const updatedRows = transactionDetails.filter((row) => row.id !== selectedId);
            onUpdateTransactionDetails(updatedRows); // Atualiza o estado no componente pai
        }
        setSelectedId(null); // Limpa o ID selecionado após fechar o diálogo
    };

    const handleEdit = (details) => {
        console.log(details);
        // Lógica para manipular a edição aqui
    };

    return (
        <TableContainer component={Paper} sx={{ margin: 1, width: '100%', overflowX: 'auto' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>Tipo</TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="right">
                            Data da Transação
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="right">
                            Preço
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="right">
                            Quantidade
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="right">
                            Total
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="right">
                            Taxa da Corretora
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="right">
                            Ações
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactionDetails?.map((details) => (
                        <TableRow key={details.id}>
                            <TableCell component="th" scope="row" style={getValueStyle(details.type)}>
                                {details.type === 'BUY' ? (
                                    <IconButton
                                        sx={{
                                            bgcolor: 'rgb(16, 185, 129)',
                                            color: 'white',
                                            '&:hover': {
                                                // Sobrescreve o estilo de hover
                                                bgcolor: 'rgb(16, 185, 129)', // Mesma cor de fundo do estado normal
                                                color: 'white', // Mesma cor do ícone do estado normal
                                            },
                                        }}
                                        title="Compra"
                                        size="small"
                                    >
                                        <TrendingUpIcon />
                                    </IconButton>
                                ) : (
                                    <>
                                        <IconButton
                                            sx={{
                                                bgcolor: 'rgb(240, 68, 56)',
                                                color: 'white',
                                                '&:hover': {
                                                    // Sobrescreve o estilo de hover
                                                    bgcolor: 'rgb(240, 68, 56)', // Mesma cor de fundo do estado normal
                                                    color: 'white', // Mesma cor do ícone do estado normal
                                                },
                                            }}
                                            title="Venda"
                                            size="small"
                                        >
                                            <TrendingDown />
                                        </IconButton>
                                    </>
                                )}
                            </TableCell>
                            <TableCell align="right">{new Date(details.transaction_date).toLocaleDateString()}</TableCell>
                            <TableCell align="right">{details.price}</TableCell>
                            <TableCell align="right">{details.amount}</TableCell>
                            <TableCell align="right">{details.total}</TableCell>
                            <TableCell align="right">{details.broker_fee}</TableCell>
                            <TableCell align="right">
                                <IconButton onClick={() => handleDelete(details.id)}>
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton onClick={() => handleEdit(details)}>
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <DialogDeleteTransactionDetails open={openDialog} onClose={handleCloseDialog}></DialogDeleteTransactionDetails>
        </TableContainer>
    );
}

export default TransactionDetails;
