import React from 'react';
import OrderFiat from '../../components/OrderFiat';
import LogoutButton from '../../components/LogoutButton';
import { Divider, Paper, Typography } from '@mui/material';
import ResetDataCard from '../../components/ResetDataCard';

function Settings() {
    return (
        <Paper style={{ padding: 16 }}>
            <Typography variant="h6" gutterBottom>
                Importar Transações
            </Typography>

            <Divider sx={{ mx: -2 }}></Divider>

            <OrderFiat></OrderFiat>
            <LogoutButton></LogoutButton>
            <ResetDataCard></ResetDataCard>
        </Paper>
    );
}

export default Settings;
