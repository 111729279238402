import React, { useState, useEffect } from 'react';

import { TextField, Button, FormControl, InputAdornment, MenuItem, Paper, Typography, Divider, Grid, Container, Snackbar, Alert } from '@mui/material';
import CryptoAutocomplete from '../../components/CryptoAutocomplete';
import axios from 'axios';
import WalletIcon from '@mui/icons-material/Wallet';

function Crypto() {
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [price, setPrice] = useState('');
    const [investmentDate, setInvestmentDate] = useState('');
    const [amount, setAmount] = useState('');
    const [brokerFee, setBrokerFee] = useState('');
    const [total, setTotal] = useState('');
    const [type, setType] = useState('BUY');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Pode ser 'success', 'error', 'warning', 'info'

    const types = [
        {
            value: 'BUY',
            label: 'Compra',
        },
        {
            value: 'SELL',
            label: 'Venda',
        },
    ];

    useEffect(() => {
        const today = new Date();
        const currentDate = today.toISOString().split('T')[0];
        setInvestmentDate(currentDate);
        setBrokerFee(0);
    }, []);

    useEffect(() => {
        // A definição da função dentro do useEffect é suficiente se ela não for reutilizada em outros lugares
        const totalValue = parseFloat(price) * parseFloat(amount);
        // A verificação de isNaN é feita diretamente no condicional
        if (!isNaN(totalValue) && isFinite(totalValue)) {
            setTotal(totalValue.toFixed(2)); // Assegura que o resultado é um número finito
        } else {
            setTotal('');
        }
    }, [price, amount]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = {
            coin_market_cap_id: selectedCurrency.coin_market_cap_id,
            price: price,
            type: type,
            transaction_date: investmentDate,
            amount: amount,
            observation: null,
            broker_fee: brokerFee,
            total: total,
        };

        const token = localStorage.getItem('access_token');

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/portfolio`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log('Sucesso:', response.data);
                setSnackbarMessage('Transação salva com sucesso!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error('Erro:', error);
                setSnackbarMessage('Erro ao salvar transação.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleChange = (event) => {
        setType(event.target.value);
    };

    return (
        <Container maxWidth="lg">
            <Paper style={{ padding: 16 }}>
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                        display: 'flex',
                        alignItems: 'center', // Alinha o ícone com o texto verticalmente
                        gap: 1, // Adiciona um espaço entre o ícone e o texto
                    }}
                    color={'#111927'}
                >
                    <WalletIcon />
                    Meus Ativos
                </Typography>
                <Divider sx={{ mx: -2 }}></Divider>

                <Grid container direction="column" margin={'normal'}>
                    <form onSubmit={handleSubmit}>
                        <FormControl margin="normal" fullWidth>
                            <br></br>
                            <CryptoAutocomplete selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} setPrice={setPrice}></CryptoAutocomplete>
                        </FormControl>

                        <Grid item>
                            <TextField
                                label="Preço"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                margin="normal"
                                type="number"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                inputProps={{
                                    min: '0',
                                    step: '0.00000001',
                                }}
                                sx={{ mb: 2 }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                label="Quantidade"
                                fullWidth
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                margin="normal"
                                type="number"
                                inputProps={{
                                    min: '0',
                                    step: '0.01',
                                }}
                                sx={{ mb: 2 }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField id="outlined-select-currency-native" select label="Tipo" fullWidth defaultValue="BUY" onChange={handleChange} margin="normal" sx={{ mb: 2 }}>
                                {types.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Data"
                                fullWidth
                                type="date"
                                value={investmentDate}
                                onChange={(e) => setInvestmentDate(e.target.value)}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                label="Taxa da Corretora"
                                fullWidth
                                value={brokerFee}
                                onChange={(e) => setBrokerFee(e.target.value)}
                                margin="normal"
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                inputProps={{
                                    min: '0',
                                    step: '0.01',
                                }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                label="Valor Total"
                                value={total}
                                fullWidth
                                onChange={(e) => setTotal(e.target.value)}
                                margin="normal"
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                inputProps={{
                                    min: '0',
                                    step: '0.01',
                                }}
                            />
                        </Grid>

                        <Grid item sx={{ mb: 2 }}>
                            <Button type="submit" variant="contained" color="primary" margin="normal">
                                Salvar
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Paper>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default Crypto;
