import React from 'react';
import { Paper, Typography, Container, Divider, TextField, Button } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

function Profile() {
    const { user } = useAuth();
    console.log(user);

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" component="h2" gutterBottom>
                    Dados da conta
                </Typography>
                <TextField label="Nome" value={user.name} required margin="normal"></TextField>
                <TextField label="e-Mail" value={user.email} required margin="normal"></TextField>
                <TextField label="Senha" value={user.password} type="password" required margin="normal"></TextField>
                <TextField label="Confirme a senha" value={user.password} required margin="normal"></TextField>
                <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                    Atualizar Dados
                </Button>
            </Paper>
            <Divider> </Divider>
            <Divider> </Divider>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" component="h2" gutterBottom>
                    Minha assinatura
                </Typography>
            </Paper>
        </Container>
    );
}

export default Profile;
