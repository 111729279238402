import './styles.css';

import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Collapse,
    Box,
    Typography,
    CircularProgress,
    Tooltip,
    Divider,
    Chip,
    LinearProgress,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import TransactionDetails from '../../components/TransactionDetails';
import WalletIcon from '@mui/icons-material/Wallet';
import DialogDeleteTransactionDetails from '../../components/DialogDeleteTransactionDetails';

function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    const [transactionDetails, setTransactionDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handleDelete = (id) => {
        setSelectedId(id); // Define o ID da transação selecionada
        setOpenDialog(true); // Abre o diálogo
    };

    const handleCloseDialog = (confirmDelete) => {
        setOpenDialog(false);

        if (confirmDelete) {
            const token = localStorage.getItem('access_token');
            fetch(`${process.env.REACT_APP_API_URL}/api/portfolio/${selectedId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json()) // Convertendo a resposta para JSON
                .then((data) => {
                    if (data) {
                        // Atualiza o estado para remover o item da lista
                        // setRows(prevRows => prevRows.filter(row => row.coin_market_cap_id !== id));
                        console.log(data.message);
                        // setSnackbarMessage(data.message); // Usando a mensagem da API
                        // setSnackbarSeverity('success');
                        // setSnackbarOpen(true);
                    } else {
                        throw new Error('Falha ao deletar o registro');
                    }
                })
                .catch((error) => {
                    // setSnackbarMessage(error.message || 'Erro desconhecido ao deletar');
                    // setSnackbarSeverity('error');
                    // setSnackbarOpen(true);
                });
            setSelectedId(null); // Limpa o ID selecionado após fechar o diálogo
        }
    };

    // Função para determinar a cor baseada no valor
    const getValueStyle = (value) => ({
        color: value.toString().includes('-') ? 'red' : 'green',
    });

    const updateTransactionDetails = (newDetails) => {
        setTransactionDetails(newDetails);
    };

    const getProgressColor = (value) => {
        if (value <= 0 || value < 10) return 'error'; // Utiliza a cor primária para perdas
        if (value > 10 && value <= 25) return 'warning'; // Utiliza a cor de informação para lucro baixo
        if (value > 25 && value <= 50) return 'info'; // Utiliza a cor de sucesso para lucro moderado
        if (value > 50 && value <= 75) return 'primary'; // Utiliza a cor de aviso para lucro alto
        return 'success'; // Utiliza a cor de erro para lucro muito alto
    };

    // Carrega detalhes quando o usuário expande uma linha
    useEffect(() => {
        if (open && !transactionDetails) {
            setIsLoading(true);

            const token = localStorage.getItem('access_token');

            axios
                .get(`${process.env.REACT_APP_API_URL}/api/portfolio/${row.coin_market_cap_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    setTransactionDetails(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Erro ao buscar detalhes do ativo:', error);
                    setIsLoading(false);
                });
        }
    }, [open, row.id, transactionDetails, row.coin_market_cap_id]);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <Chip label={row.rank}></Chip>
                </TableCell>
                <TableCell component="th" scope="row">
                    <img src={`https://s2.coinmarketcap.com/static/img/coins/32x32/${row.coin_market_cap_id}.png`} alt="coin" className="coin-logo" /> {row.symbol}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.total_investment_usd}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.current_balance_usd}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.average_buy_price_usd}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.total_crypto}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.current_price_usd}
                </TableCell>
                {/* <TableCell component="th" scope="row">
                    {row.current_price_brl}
                </TableCell> */}
                <TableCell component="th" scope="row" style={getValueStyle(row.current_profit_loss_usd)}>
                    {row.current_profit_loss_usd}
                </TableCell>
                <TableCell component="th" scope="row" style={getValueStyle(row.current_profit_loss_usd)}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                            variant="determinate"
                            value={row.profit_percent}
                            color={getProgressColor(row.profit_percent)}
                            sx={
                                {
                                    // height: '7px',
                                }
                            }
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography variant="caption" component="div" color="text.secondary">
                            {`${Math.round(row.profit_percent)}%`}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell component="th" scope="row" style={getValueStyle(row.current_profit_loss_usd)}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress variant="determinate" value={row.profit_percent} color="secondary" />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography variant="caption" component="div" color="text.secondary">
                            {`${Math.round(row.portfolio_percent)}%`}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell component="th" scope="row">
                    <IconButton size="small" onClick={() => handleDelete(row.coin_market_cap_id)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            {open && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 1 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                {isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <TransactionDetails transactionDetails={transactionDetails} onUpdateTransactionDetails={updateTransactionDetails}>
                                        {' '}
                                    </TransactionDetails>
                                )}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
            <DialogDeleteTransactionDetails open={openDialog} onClose={handleCloseDialog}></DialogDeleteTransactionDetails>
        </React.Fragment>
    );
}

function CollapsibleTable() {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        fetch(`${process.env.REACT_APP_API_URL}/api/portfolio`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => setRows(data))
            .catch((error) => console.error('Erro ao buscar dados:', error));
    }, []);

    return (
        <TableContainer component={Paper} maxWidth="false" sx={{ mt: 4 }} style={{ padding: 16 }}>
            <Typography
                variant="h6"
                gutterBottom
                sx={{
                    display: 'flex',
                    alignItems: 'center', // Alinha o ícone com o texto verticalmente
                    gap: 1, // Adiciona um espaço entre o ícone e o texto
                }}
                color={'#111927'}
            >
                <WalletIcon />
                Meus Ativos
            </Typography>

            <Divider sx={{ mx: -2 }}></Divider>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>
                            Ranking{' '}
                            <Tooltip title="Rank das cri">
                                <IconButton size="small">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            Nome{' '}
                            <Tooltip title="Nome da Criptomoeda: Identifica a criptomoeda ou ativo digital em questão. Por exemplo, Bitcoin, Ethereum, etc.">
                                <IconButton size="small">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            Investimento total{' '}
                            <Tooltip title="Investimento Total: Refere-se ao montante total investido neste ativo específico. É o custo acumulado de todas as compras realizadas, sem considerar as vendas ou retiradas.">
                                <IconButton size="small">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            Saldo{' '}
                            <Tooltip title="Investimento Total: Refere-se ao montante total investido neste ativo específico. É o custo acumulado de todas as compras realizadas, sem considerar as vendas ou retiradas.">
                                <IconButton size="small">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>

                        <TableCell>
                            Preço médio{' '}
                            <Tooltip title="Preço Médio: O custo médio de aquisição por unidade do ativo. Calculado dividindo o total investido pela quantidade total de moedas ou tokens adquiridos.">
                                <IconButton size="small">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            Quantidade{' '}
                            <Tooltip title="Quantidade: A quantidade total do ativo que você possui. Inclui todas as suas compras acumuladas e subtrai as vendas ou retiradas.">
                                <IconButton size="small">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            Preço atual{' '}
                            <Tooltip title="O valor de mercado atual de uma única unidade do ativo. Flutua com base nas condições de mercado e na oferta e demanda.">
                                <IconButton size="small">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        {/* <TableCell>
                            Preço atual{' '}
                            <Tooltip title="O valor de mercado atual de uma única unidade do ativo. Flutua com base nas condições de mercado e na oferta e demanda.">
                                <IconButton size="small">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell> */}
                        <TableCell>
                            PNL
                            <Tooltip title="PNL: Representa o ganho ou perda não realizado em seu investimento. Calculado pela diferença entre o valor de mercado atual do seu ativo e o total investido.">
                                <IconButton size="small">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            % PNL
                            <Tooltip title="PNL: Representa o ganho ou perda não realizado em seu investimento. Calculado pela diferença entre o valor de mercado atual do seu ativo e o total investido.">
                                <IconButton size="small">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            % Portfólio
                            <Tooltip title="PNL: Representa o ganho ou perda não realizado em seu investimento. Calculado pela diferença entre o valor de mercado atual do seu ativo e o total investido.">
                                <IconButton size="small">
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>Ação</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.id} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CollapsibleTable;
