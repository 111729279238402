import axios from 'axios';

const fetchTopCryptos = async (type) => {
    try {
        // Atribui 'ASC' se o tipo não for 'win', caso contrário 'DESC'
        const orderBy = type === 'win' ? 'DESC' : 'ASC';
        const token = localStorage.getItem('access_token');

        // Verifica se a variável de ambiente REACT_APP_API_URL está definida
        const apiUrl = process.env.REACT_APP_API_URL;
        if (!apiUrl) {
            throw new Error('REACT_APP_API_URL não está definida.');
        }

        const response = await axios.get(`${apiUrl}/api/dashboard/topcrypto`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                limit: 5,
                orderBy: orderBy,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar as top criptomoedas:', error);
        // É uma boa prática propagar o erro ou tratá-lo de forma adequada
        throw error;
    }
};

export { fetchTopCryptos };
