import React, { useState } from 'react';
import axios from 'axios'; // Certifique-se de ter instalado o axios
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField, Typography, Alert } from '@mui/material';

function OrderFiat() {
    const [operadora, setOperadora] = useState('');
    const [file, setFile] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    const handleOperadoraChange = (event) => {
        setOperadora(event.target.value);
    };

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        const fileExtension = uploadedFile?.name.split('.').pop().toLowerCase();
        if (fileExtension === 'csv' || fileExtension === 'xlsx') {
            setFile(uploadedFile);
        } else {
            setSnackbarMessage('Por favor, selecione um arquivo CSV ou XLSX.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!file) {
            setSnackbarMessage('Nenhum arquivo selecionado.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('operadora', operadora);
        const token = localStorage.getItem('access_token');
        try {
            // Substitua a URL pelo seu endpoint
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/portfolio/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });
            setSnackbarMessage('Arquivo enviado com sucesso!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Erro ao enviar arquivo.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <FormControl fullWidth margin="normal">
                <InputLabel id="operadora-label">Operadora</InputLabel>
                <Select labelId="operadora-label" id="operadora" value={operadora} label="Operadora" onChange={handleOperadoraChange}>
                    <MenuItem value="Binance">Binance</MenuItem>
                    <MenuItem value="OKX">OKX</MenuItem>
                </Select>
            </FormControl>

            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Upload de Arquivo
            </Typography>
            <TextField type="file" onChange={handleFileChange} fullWidth margin="normal" InputLabelProps={{ shrink: true }} />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Enviar
            </Button>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default OrderFiat;
