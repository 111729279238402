import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert } from '@mui/material';

function ResetDataCard() {
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    // Função para abrir o diálogo de confirmação
    const handleClickOpen = () => {
        setOpen(true);
    };

    // Função para fechar o diálogo de confirmação e o Snackbar
    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Função chamada para zerar os dados do usuário
    const resetData = () => {
        console.log('Zerando todos os dados do usuário...');
        const token = localStorage.getItem('access_token');

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/transactions/reset`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
            .then((response) => {
                console.log('Resposta:', response.data);
                // Sucesso
                setSnackbarMessage('Dados financeiros resetados com sucesso!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                handleClose(); // Fecha o diálogo após a confirmação e sucesso da requisição
            })
            .catch((error) => {
                let errorMsg = 'Erro ao tentar resetar as transações.';
                if (error.response) {
                    console.error('Erro:', error.response.data);
                    errorMsg = 'Falha ao resetar as transações.';
                } else if (error.request) {
                    console.error('Erro:', error.request);
                    errorMsg = 'Nenhuma resposta do servidor ao resetar as transações.';
                } else {
                    console.error('Erro:', error.message);
                }
                setSnackbarMessage(errorMsg);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    return (
        <Card sx={{ maxWidth: 345, margin: 'auto', mt: 5 }}>
            {' '}
            {/* Estilos para melhor visualização em mobile */}
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    Começar do zero
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Clicando no botão abaixo, você irá zerar todos os seus dados financeiros do sistema, preservando seu cadastro. Essa ação é irreversível.
                </Typography>
                <Button variant="outlined" color="error" onClick={handleClickOpen}>
                    Reiniciar conta
                </Button>
            </CardContent>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirmação</DialogTitle>
                <DialogContent>
                    <DialogContentText>Tem certeza que deseja apagar todos os seus dados financeiros? Essa ação não pode ser desfeita.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={resetData} color="error">
                        Reiniciar conta
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Card>
    );
}

export default ResetDataCard;
