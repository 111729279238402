import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute'; // Importe o PrivateRoute

import Home from './pages/home/home';
import Crypto from './pages/crypto/crypto';
import Cryptoindex from './pages/crypto/index';
import Settings from './pages/settings/settings';
import Login from './pages/login/login';
import Register from './pages/register/register';
import Profile from './pages/profile/profile';

const AppRoutes = () => {
    return (
        <Routes>
            <Route
                path="/crypto"
                element={
                    <PrivateRoute>
                        {' '}
                        <Crypto />{' '}
                    </PrivateRoute>
                }
            />
            <Route
                path="/crypto/index"
                element={
                    <PrivateRoute>
                        <Cryptoindex />
                    </PrivateRoute>
                }
            />
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <Home />
                    </PrivateRoute>
                }
            />
            <Route
                path="/settings"
                element={
                    <PrivateRoute>
                        <Settings />
                    </PrivateRoute>
                }
            />
            <Route
                path="/profile"
                element={
                    <PrivateRoute>
                        <Profile />
                    </PrivateRoute>
                }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
        </Routes>
    );
};

export default AppRoutes;
