import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function DialogDeleteTransactionDetails({ open, onClose }) {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <DeleteIcon sx={{ color: 'red' }}></DeleteIcon>
                {'Confirmar exclusão?'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">Você tem certeza que deseja excluir o ativo selecionado?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(false)} autoFocus>
                    Não
                </Button>
                <Button onClick={() => onClose(true)}>Sim</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogDeleteTransactionDetails;
