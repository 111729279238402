import SavingsIcon from '@mui/icons-material/Savings';
import { Box, CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import IncrementalCounter from './IncrementalCounter';

function BalanceCard() {
    const [balance, setBalance] = useState('$1.200');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchProfit = async () => {
            const token = localStorage.getItem('access_token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/balance`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setBalance(parseFloat(response.data.data)); // Converte e armazena como número
            } catch (error) {
                console.error('Erro:', error);
                setError('Falha ao carregar o lucro.');
            } finally {
                setLoading(false);
            }
        };
        fetchProfit();
    }, []);

    return (
        <Card sx={{ minWidth: 275, marginBottom: 2, backgroundColor: '#00ba76', color: '#ffffff' }} elevation={3} className="">
            <CardContent>
                <Typography sx={{ fontSize: 14, color: 'white' }} gutterBottom>
                    Saldo bruto
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <Typography variant="body2" style={{ color: '#ff1744' }}>
                            {error}
                        </Typography>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h5" component="div" sx={{ mr: 1 }}>
                                <IncrementalCounter start={0} end={balance} duration={3000} />
                            </Typography>
                            <SavingsIcon sx={{ color: 'white' }} />
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
}

export default BalanceCard;
