// CryptoAutocomplete.js

import React, { useState, useEffect } from 'react';
import { Autocomplete, Avatar, Box, TextField } from '@mui/material';
import axios from 'axios';

function CryptoAutocomplete({ selectedCurrency, setSelectedCurrency, setPrice }) {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (inputValue === '') {
            setOptions([]);
            return;
        }

        const fetchCurrencies = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/crypto-search?query=${inputValue}`);
                setOptions(response.data);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        };

        fetchCurrencies();
    }, [inputValue]);

    return (
        <Autocomplete
            value={options.find((option) => option.coin_market_cap_id === selectedCurrency)}
            onChange={(event, newValue) => {
                setSelectedCurrency(newValue ? newValue : null);
                if (newValue) {
                    setPrice(newValue.price);
                }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    <Avatar src={option.icon} sx={{ width: 24, height: 24, marginRight: 1 }} />
                    {option.name}
                </Box>
            )}
            options={options}
            isOptionEqualToValue={(option, value) => option.coin_market_cap_id === value.coin_market_cap_id}
            getOptionLabel={(option) => option.name}
            loadingText="Buscando... "
            loading
            renderInput={(params) => <TextField {...params} label="Informe o nome da criptomoeda" variant="outlined" />}
        />
    );
}

export default CryptoAutocomplete;
