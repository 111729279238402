import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { CircularProgress } from '@mui/material';

const PrivateRoute = ({ children }) => {
    const { user, login } = useAuth(); // Use `login` do seu contexto de autenticação
    const [checkingAuth, setCheckingAuth] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('access_token');
            if (token) {
                const isValidToken = await validateToken(token);
                if (isValidToken) {
                    login(isValidToken); // Use a função `login` para atualizar o estado do usuário
                }
            }
            setCheckingAuth(false);
        };

        checkAuth();
    }, [login]); // Adicione `login` como dependência para garantir a atualização se a função mudar

    if (checkingAuth) {
        return <CircularProgress />;
    }

    return user ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;

async function validateToken() {
    const token = localStorage.getItem('access_token');

    if (!token) {
        console.log('No token found');
        return false;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/validate-token`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();

        if (response.ok) {
            console.log('Token is valid', data);
            return true; // ou você pode retornar os dados do usuário, se necessário
        } else {
            console.log('Token validation failed', data);
            return false;
        }
    } catch (error) {
        console.error('Error validating token', error);
        return false;
    }
}
