import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Typography } from '@mui/material';
import ReactECharts from 'echarts-for-react';

function CryptoSummaryChart() {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchProfit = async () => {
            const token = localStorage.getItem('access_token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/crypto-summary-history`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const formattedData = response.data.map((item) => ({
                    ...item,
                    interval: new Date(item.interval).toLocaleTimeString(), // Mantém como string para o eixo X
                    sum_profit_loss_usd: parseFloat(item.sum_profit_loss_usd),
                }));
                setData(formattedData);
            } catch (error) {
                console.error('Falha ao carregar o lucro.', error);
            }
        };

        fetchProfit();
    }, []);

    const getOption = () => ({
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                },
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.map((item) => item.interval),
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: 'Lucro acumulado',
                type: 'line',
                // Remova areaStyle para uma linha simples
                // Remova smooth ou defina como false para evitar linhas suavizadas
                emphasis: {
                    focus: 'series',
                },
                data: data.map((item) => item.sum_profit_loss_usd),
            },
        ],
    });

    return (
        <Card sx={{ minWidth: 275, marginBottom: 2, backgroundColor: '#424242', color: '#ffffff' }} elevation={3}>
            <CardContent>
                <Typography sx={{ fontSize: 14, color: 'white' }} gutterBottom>
                    Lucro acumulado
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <ReactECharts option={getOption()} style={{ height: '300px', width: '100%' }} />
                </Box>
            </CardContent>
        </Card>
    );
}

export default CryptoSummaryChart;
