import React from 'react';
import { Card, CardContent, Typography, Box, IconButton, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

function GoalCard({ currentAmount, goalAmount, endDate }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const progress = (currentAmount / goalAmount) * 100;
    const data = [
        { name: 'Progress', value: progress },
        { name: 'Remaining', value: 100 - progress },
    ];
    const COLORS = ['#00acc1', '#e0e0e0'];

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
            }}
        >
            <Box
                sx={{
                    width: isMobile ? '100%' : '35%',
                    height: isMobile ? 150 : 200, // Menor altura em dispositivos móveis
                    position: 'relative',
                }}
            >
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie data={data} cx="50%" cy="50%" startAngle={210} endAngle={-30} innerRadius="70%" outerRadius="100%" fill="#8884d8" paddingAngle={0} dataKey="value" cornerRadius={20}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <Box
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <Typography variant={isMobile ? 'subtitle1' : 'h5'} component="div" color="textSecondary">
                        {`${Math.round(progress)}%`}
                    </Typography>
                </Box>
            </Box>
            <CardContent
                sx={{
                    flex: isMobile ? '1 0 auto' : undefined,
                    mt: isMobile ? 2 : undefined,
                }}
            >
                <Typography variant="h6">Meta</Typography>
                <Typography variant="subtitle1">Data fim: {endDate}</Typography>
                <Typography variant="subtitle1">
                    Meta da Carteira:
                    <Tooltip title="O valor que você pretende atingir até a data final">
                        <HelpOutlineIcon fontSize="small" sx={{ ml: 0.5 }} />
                    </Tooltip>
                    {` ${goalAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                </Typography>
            </CardContent>
            <Box
                sx={{
                    order: isMobile ? -1 : undefined,
                    mt: isMobile ? 2 : undefined,
                }}
            >
                <IconButton>
                    <SettingsIcon />
                </IconButton>
            </Box>
        </Card>
    );
}

export default GoalCard;
