import './TopCryptos.css';

import React, { useEffect, useState } from 'react';
import { fetchTopCryptos } from '../services/CryptoService';
import { List, ListItem, ListItemText, CircularProgress, Divider, Card, CardContent, CardHeader, Typography, IconButton } from '@mui/material';

// Corrigido: Desestruturação das props em um único objeto
const TopCryptos = ({ type, title }) => {
    const [cryptos, setCryptos] = useState([]);
    const [loading, setLoading] = useState(true); // Ajuste inicial do estado de loading para true

    useEffect(() => {
        const getCryptos = async () => {
            setLoading(true);
            try {
                const data = await fetchTopCryptos(type);
                setCryptos(data.data); // Certifique-se de que esta é a estrutura correta retornada pela sua API
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            } finally {
                setLoading(false);
            }
        };
        getCryptos();
    }, [type]);

    return (
        <div>
            {loading ? (
                <CircularProgress />
            ) : (
                <Card className="card">
                    <CardHeader className="CardHeader" title={title} titleTypographyProps={{ align: 'center', variant: 'h6' }} />
                    <Divider></Divider>
                    <CardContent className="card">
                        <List sx={{ width: '100%' }} className="list">
                            {cryptos.map((crypto, index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && <Divider sx={{ mx: -2 }} />}
                                    <ListItem>
                                        <IconButton>
                                            <Typography sx={{ paddingRight: 2 }}>{index + 1}</Typography>
                                        </IconButton>

                                        <img src={`${crypto.icon}`} alt="coin" className="coin-logo" />
                                        <ListItemText primary={`${crypto.symbol} $${crypto.current_profit_loss_usd}`} primaryTypographyProps={{ fontWeight: 400 }} />
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default TopCryptos;
